import React, { useContext, useEffect, useState } from "react";
import { baseUrl, dataContext } from "../../App";
import "./Profile.css";
import avatarImg from "../../assets/img/leaderboard/avatar.png";
import scoreBgImg from "../../assets/img/v2/score_bg.png";
import ProfileEditModal from "../ProfileEditModal/ProfileEditModal";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import ReferralHistory from "../Modal/ReferralHistory/ReferralHistory";

const Profile = ({ showLoginModal, setShowLoginModal }) => {
  const {token, setToken, playerData, setPlayerData, events, setEvents} =
    useContext(dataContext);
  const [showProfileEditModal, setShowProfileEditModal] = useState(false);
  const [showReferralHistory, setShowReferralHistory] = useState(false);
  useEffect(() => {
    if (playerData?.id) {
    } else {
      setShowLoginModal(true);
    }
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("profile", msisdn);
    analyticEvent('profile', 'profile-view')
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventId:event.id,
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
        rank: userLeaderboard[0]?.User_Rank,
      };
    } else {
      return {
        eventId:event.id,
        eventTitle: event.events,
        score: "-",
        rank: "-",
      };
    }
  });
  console.log(userScore);
  return (
    <>
      <div className="profileWrapper">
<div className="borderWrapper">
<div className="outerBoxLeft"></div>
        <div className="outerBoxRight"></div>
</div>
        <div className="profileHeaderCardWrapper">
          <div className="profileCardHeader"> প্রোফাইল </div>
        </div>
        <div className="profileUserBody mt-3">
          {/* <img src={require(`../../assets/avatar/avatar${(playerData?.avatar_id)?playerData.avatar_id:1}.png`)} className="profileAvatar" alt="" srcSet="" /> */}
          <img
            src={
              playerData?.avatar_img
                ? `${baseUrl}${playerData?.avatar_img}`
                : require(`../../assets/avatar/avatar${
                    playerData?.avatar_id ? playerData.avatar_id : 1
                  }.png`)
            }
            className="profileAvatar"
            alt=""
            srcSet=""
          />

          <div className="profileUsername">
            {playerData?.name ? playerData.name : "ইউজারনেম"}
          </div>
          <div className="profileMobilenumber">
            {playerData?.id
              ? `0${playerData.msisdn.toString().slice(-10)}`
              : "-"}
          </div>
          {playerData.id && (
            <div
              className="editBtn"
              onClick={() => {
                setShowProfileEditModal(true);
                analyticEvent('profile', 'profile-edit-view')
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </div>
          )}
        </div>
        <div className="profileStatusBody mt-3 mb-1">
        {/* <span style={{ color: "#00ceffe8",fontSize:'16px' }}>(<u>Subscribe</u>)</span> */}
        {playerData?.isSubscribe &&<div className="profileStatus mb-1">
          Referral Code:{" "}
            {playerData?.id ? (
              ((playerData?.code)? <>
              <span style={{ color: "#a0f34f" }} onClick={() => {navigator.clipboard.writeText(playerData?.code)}}>&nbsp; {playerData?.code} &nbsp; <i className="fa-solid fa-copy copyIcon"></i></span>
              {/* <span> &nbsp; &nbsp; <a href={`fb-messenger://share/?text=${playerData?.code}`}><i class="fab fa-facebook-messenger" style={{ fontSize:"larger" }}></i></a></span> */}
              <span> &nbsp; &nbsp; <a href={`whatsapp://send?text=${playerData?.code}`}><i className="fab fa-whatsapp" style={{ fontSize:"larger",color:'#a0f34f' }}></i></a></span>
              </>
              :<span style={{ color: "#f00" }}>&nbsp; -</span>)
            ) : (
              `-`
            )}
          </div>}
          {playerData?.isSubscribe && <div className="profileStatus mb-1">
            Referral Score:{" "}
            {playerData?.id ? (
              (<span style={{ color: "#EB863D" }}>&nbsp; {playerData?.point} &nbsp; 
              <span onClick={() => {
                setShowReferralHistory(true);
              }} style={{ color:'#EB863D' }}> &nbsp; 
              <u>History</u> 
              &nbsp;
              </span>
              </span>)
            ) : (
              `-`
            )}
          </div>}
        {/* <span style={{ color: "#00ceffe8",fontSize:'16px' }}>(<u>Subscribe</u>)</span> */}
          <div className="profileStatus mb-1">
            স্ট্যাটাস:{" "}
            {playerData?.id ? (
              ((playerData?.isSubscribe)?<span style={{ color: "#a0f34f" }}>সাবস্ক্রাইবড </span>:<span style={{ color: "#f00" }}>আনসাবস্ক্রাইবড </span>)
            ) : (
              `-`
            )}
          </div>
          <div className="profileLastPayment">
            সর্বশেষ পেমেন্ট: &nbsp; {playerData?.payment_history?.last_payment ? (
              (<span style={{ color: "#fff" }}>&nbsp; {playerData?.payment_history?.last_payment}</span>)
            ) : (
              `-`
            )}
            {/* {playerData?.id ? <span style={{ color: "chartreuse" }}> 30/10/2023</span> : `-`} */}
          </div>
          <div className="profileNextPayment" >
            পরবর্তী পেমেন্ট: &nbsp; {playerData?.payment_history?.next_payment ? (
              (<span style={{ color: "#fff" }}>&nbsp; {playerData?.payment_history?.next_payment}</span>)
            ) : (
              `-`
            )}
            {/* {playerData?.id ? <span> 29/11/2023</span> : `-`} */}
          </div>
        </div>
        <div className="profileScore">
          <div className="wrapperTitle" style={{ paddingLeft: "5%" }}>
            ইভেন্ট স্কোর
          </div>
          <div className="scoreBox">
            {userScore.map((singleScore) => (
              <div className="singleScoreDiv" key={singleScore.eventId}>
                <div className="">{singleScore.eventTitle}</div>
                <div className="">#{singleScore.rank}</div>
                <div className="">স্কোর: {singleScore.score}</div>
              </div>
            ))}
          </div>

          {/* <img src={scoreBgImg} className="profileScoreBg" alt="" srcSet="" /> */}
          {/* <div className="profileEventScore">
            {playerData?.id ? playerData.leaderboard[1]?.score : "-"}
            </div>
            <div className="profileDailyScore">
            {playerData?.id ? playerData.leaderboard[0]?.score : "-"}
          </div> */}
        </div>
          <span className="dotDesign">...</span>
      </div>
      {showProfileEditModal && (
        <ProfileEditModal
          showProfileEditModal={showProfileEditModal}
          setShowProfileEditModal={setShowProfileEditModal}
        ></ProfileEditModal>
      )}
      {showReferralHistory && (
        <ReferralHistory
          showReferralHistory={showReferralHistory}
          setShowReferralHistory={setShowReferralHistory}
          setShowLoginModal={setShowLoginModal}
        ></ReferralHistory>
      )}
    </>
  );
};

export default Profile;
